<template>
    <main-block-with-overview v-if="showMainBlock" />
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import MainBlockWithOverview from '@/components/main-block/MainBlockWithOverview.vue';
    
    export default {
        name: 'ProjectPage',
        components: {
            'main-block-with-overview': MainBlockWithOverview,
        },
        beforeRouteUpdate(to, from, next) {
            if (to.path !== from.path) {
                this.resetFilter();
            }
            next();
        },
        computed: {
            ...mapGetters([
                'getUserDataProp',
            ]),
            showMainBlock(){
                return !!this.getUserDataProp('email');
            },
        },
        methods: {
            ...mapActions([
                'resetFilter',
            ])
        }
    }
</script>
